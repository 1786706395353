import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				О нас | Откройте для себя мастерскую АвтоПульс
			</title>
			<meta name={"description"} content={"Оживите историю своего двигателя - Путешествие в сердце мастерской АвтоПульс"} />
			<meta property={"og:title"} content={"О нас | Откройте для себя мастерскую АвтоПульс"} />
			<meta property={"og:description"} content={"Оживите историю своего двигателя - Путешествие в сердце мастерской АвтоПульс"} />
			<meta property={"og:image"} content={"https://xjuxly.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://xjuxly.com/img/34235130.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://xjuxly.com/img/34235130.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://xjuxly.com/img/34235130.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://xjuxly.com/img/34235130.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://xjuxly.com/img/34235130.png"} />
			<meta name={"msapplication-TileImage"} content={"https://xjuxly.com/img/34235130.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--primary"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					О нас
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				Автомастерская АвтоПульс - это не просто место для обслуживания и ремонта автомобилей, это святилище, где к каждому автомобилю относятся с уважением, а каждый клиент является членом семьи. Наши корни глубоко уходят в любовь к автомобилям и обществу, которое мы обслуживаем. Здесь автомобили - это не просто машины, это истории на колесах, каждая из которых имеет свой уникальный пульс.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://xjuxly.com/img/5.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
			Суть АвтоПульса
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			В основе мастерской АвтоПульс лежит стремление к совершенству и преданность ремеслу ухода за автомобилем. Наша история - это страсть, точность и стремление к автомобильному совершенству. При каждом обслуживании мы стремимся не только оправдать, но и превзойти ожидания, гарантируя, что производительность вашего автомобиля будет оптимальной для предстоящего путешествия.
			</Text>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
					as="h2"
				>
					Что отличает АвтоПульс
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					Клиентоориентированный подход: Ваше удовлетворение - наш главный приоритет. Мы слушаем, заботимся и предоставляем услуги с учетом уникальных потребностей вашего автомобиля.
				</Text>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 22px 0px"
					lg-width="50%"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 16px 0px 0px"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-primary"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						✓
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Передовая диагностика
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
						Используя новейшие технологии, мы проводим точную диагностику, гарантируя, что ваш автомобиль получит именно тот уход, который ему необходим.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="50%"
					margin="0px 0px 22px 0px"
					lg-padding="0px 0px 0px 16px"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-primary"
						border-radius="50px"
						display="flex"
						align-items="center"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						✓
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Практика устойчивого развития
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
						Мы стремимся сократить воздействие на окружающую среду, используя экологически чистые продукты и перерабатывая материалы.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="50%"
					lg-padding="0px 0px 0px 16px"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-primary"
						border-radius="50px"
						display="flex"
						align-items="center"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						✓
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Участие в жизни общества
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
						АвтоПульс - это не просто мастерская, это участник сообщества, предлагающий мастер-классы по уходу за автомобилем и поддерживающий местные инициативы.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://xjuxly.com/img/3.jpg"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
				/>
			</Box>
		</Section>
		<Section padding="40px 0 80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
			Создайте путешествие своего автомобиля вместе с нами
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			В АвтоПульс мы верим, что у каждого автомобиля есть своя история, и мы здесь для того, чтобы каждая глава была лучше предыдущей. Будь то плановое техническое обслуживание или сложный ремонт, путешествие вашего автомобиля важно для нас. Присоединяйтесь к семье АвтоПульс и позвольте нам стать частью истории вашего автомобиля, обеспечив его бесперебойную работу во время всех предстоящих приключений.
			</Text>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});